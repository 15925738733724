/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

/*=========================================================
        Models
=========================================================*/

interface Props {
    children: React.JSX.Element;
    style?: React.CSSProperties;
    className?: string;
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class PageWrapper extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
        
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <div className="d-flex flex-column " style={{minHeight: "100vh", maxWidth: "100vw", overflow: 'hidden'}}>
                <PageHeader/>
                <div className="flex-fill d-flex justify-content-center">
                    <div className={'w-100 ' + (this.props.className ? ' ' + this.props.className : '')} style={this.props.style}>
                        {this.props.children}
                    </div>
                </div>
                <PageFooter/>
            </div>
        )
    }
}