/*=========================================================
        Imports
=========================================================*/

//CSS
// import 'bootstrap/dist/css/bootstrap.css';
import './css/custom.css';
import './css/.global.css';
//Package Imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
//Script Imports
import reportWebVitals from './reportWebVitals';
//Pages
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';


/*=========================================================
        Constants
=========================================================*/

const ROUTER = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="about-us" element={<AboutUs/>} />
      <Route path="contact-us" element={<ContactUs/>} />
      <Route path="gallery" element={<Gallery/>} />
      <Route path="" element={<Home/>} />
    </Route>
  )
);

const REACT_ROOT = ReactDOM.createRoot(document.getElementById('root')!);

/*=========================================================
        Render
=========================================================*/

REACT_ROOT.render(
  <React.StrictMode>
    <RouterProvider router={ROUTER}/>
  </React.StrictMode>
);

/*=========================================================
        Misc Scripts
=========================================================*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

  
  