/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import './PageBanner.css';

/*=========================================================
        Models
=========================================================*/

interface Props {
    imageSrc: string;
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class BurgerMenu extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <div 
                style={{
                    height: 400, 
                    "--image-src": `url('${this.props.imageSrc}')`
                } as React.CSSProperties}
                className="w-100 gallery-banner-image d-flex align-items-end justify-content-center" 
            />
        )
    }
}