/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import PageWrapper from '../components/PageWrapper';

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class ContactUs extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <PageWrapper className="d-flex justify-content-center" style={{maxWidth: '98%'}}>
                    <div className="text-center h-fit-content pt-2 d-flex flex-column gap-2" style={{width: 800}}>
                        <h1 className="fw-bold fst-italic text-primary-emphasis">Contact Us</h1>
                        <div className="global-text-body text-primary-emphasis">
                            Should you wish to explore any possibility of visiting the garden or lending your support through donation of any plants, 
                            don’t hesitate to contact us at <a href="mailto:jeffreyspencerukip@aol.co.uk">jeffreyspencerukip@aol.co.uk</a>
                        </div>
                        <div className="global-text-body text-primary-emphasis pt-2">
                            The garden's location can be seen below. It's situated within the alley 
                            connecting Magdalene Crescent and Valentine Way in Silver End.
                        </div>
                        <div className="pt-2">
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d839.8471118089668!2d0.622374263482427!3d51.84494092927627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDUwJzQxLjgiTiAwwrAzNycyMy4yIkU!5e1!3m2!1sen!2suk!4v1691252512500!5m2!1sen!2suk"
                                style={{
                                    border: 0,
                                    maxWidth: "100%",
                                    width: "450px",
                                    aspectRatio: '1.5'
                                }}
                                className="border border-primary-subtle"
                                title="Location"
                                loading="lazy"
                            />
                       </div>
                    </div>
                </PageWrapper>
            </React.Fragment>
        )
    }

}