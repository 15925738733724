export const GALLERY_SECTIONS = [
    Object.freeze({
        SectionTitle: 'Where We Started',
        Images: [
            {Src: "/media/garden-photos-hd/HumbleBeginnings/overgrown-one.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/overgrown-one_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/overgrown-two.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/overgrown-two_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/overgrown-three.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/overgrown-three_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/overgrown-four.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/overgrown-four_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-one.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-one_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-two.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-two_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-three.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/grass-cut-three_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/gras-cut-four.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/gras-cut-four_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/slabs-in-progress.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/slabs-in-progress_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/slabs.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/slabs_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/slabs-two.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/slabs-two_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/slab-decoration.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/slab-decoration_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/pond.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/pond_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/garden-plot.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/garden-plot_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/progress.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/progress_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
            {Src: "/media/garden-photos-hd/HumbleBeginnings/shed-and-progress.jpg", ThumbnailSrc: "/media/garden-photos-hd/HumbleBeginnings/shed-and-progress_thumbnail.jpg", Alt: "Humble Beginnings Photo"},
        ]
    }),
    Object.freeze({
        SectionTitle: 'Entrance',
        Images: [
            {Src: "/media/garden-photos-hd/Entrance/IMG_8676.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8676_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8677.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8677_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8678.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8678_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8679.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8679_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8725.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8725_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8726.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8726_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8727.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8727_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8728.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8728_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8729.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8729_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8730.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8730_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8731.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8731_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8748.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8748_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8749.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8749_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8750.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8750_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8751.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8751_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8778.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8778_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8779.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8779_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8780.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8780_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8781.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8781_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8782.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8782_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8783.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8783_thumbnail.jpg", Alt: "Entrance Photo"},
            {Src: "/media/garden-photos-hd/Entrance/IMG_8784.jpg", ThumbnailSrc: "/media/garden-photos-hd/Entrance/IMG_8784_thumbnail.jpg", Alt: "Entrance Photo"},
        ]
    }),
    Object.freeze({
        SectionTitle: 'Center',
        Images: [
            {Src: "/media/garden-photos-hd/Middle/IMG_8696.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8696_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8697.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8697_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8698.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8698_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8701.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8701_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8702.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8702_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8704.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8704_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8707.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8707_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8713.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8713_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8714.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8714_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8732.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8732_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8733.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8733_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8734.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8734_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8738.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8738_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8739.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8739_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8741.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8741_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8742.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8742_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8743.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8743_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8744.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8744_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8745.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8745_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8746.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8746_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8757.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8757_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8758.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8758_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8760.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8760_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8767.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8767_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8773.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8773_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8774.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8774_thumbnail.jpg", Alt: "Center Photo"},
            {Src: "/media/garden-photos-hd/Middle/IMG_8785.jpg", ThumbnailSrc: "/media/garden-photos-hd/Middle/IMG_8785_thumbnail.jpg", Alt: "Center Photo"}
        ]
    }),
    Object.freeze({
        SectionTitle: 'Plants',
        Images: [
            {Src: "/media/garden-photos-hd/Plants/IMG_8680.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8680_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8681.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8681_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8682.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8682_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8688.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8688_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8689.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8689_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8690.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8690_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8703.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8703_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8710.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8710_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8712.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8712_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8720.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8720_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8721.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8721_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8722.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8722_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8723.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8723_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8737.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8737_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8761.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8761_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8762.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8762_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8763.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8763_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8764.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8764_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8765.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8765_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8766.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8766_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8768.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8768_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8775.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8775_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8776.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8776_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8777.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8777_thumbnail.jpg", Alt: "Plant Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8699.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8699_thumbnail.jpg", Alt: "Plants Photo"},
            {Src: "/media/garden-photos-hd/Plants/IMG_8700.jpg", ThumbnailSrc: "/media/garden-photos-hd/Plants/IMG_8700_thumbnail.jpg", Alt: "Plants Photo"}
        ]
    }),
    Object.freeze({
        SectionTitle: 'Pond',
        Images: [
            {Src: "/media/garden-photos-hd/Pond/IMG_8683.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8683_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8684.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8684_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8685.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8685_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8686.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8686_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8687.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8687_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8691.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8691_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8692.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8692_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8693.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8693_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8694.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8694_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8709.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8709_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8711.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8711_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8715.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8715_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8716.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8716_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8717.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8717_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8718.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8718_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8719.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8719_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8724.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8724_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8747.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8747_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8752.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8752_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8753.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8753_thumbnail.jpg", Alt: "Pond Photo"},
            {Src: "/media/garden-photos-hd/Pond/IMG_8772.jpg", ThumbnailSrc: "/media/garden-photos-hd/Pond/IMG_8772_thumbnail.jpg", Alt: "Pond Photo"}
        ]
    }),
    Object.freeze({
        SectionTitle: 'Vegetables',
        Images: [
            {Src: "/media/garden-photos-hd/Vegetables/IMG_8695.jpg", ThumbnailSrc: "/media/garden-photos-hd/Vegetables/IMG_8695_thumbnail.jpg", Alt: "Vegetables Photo"},
            {Src: "/media/garden-photos-hd/Vegetables/IMG_8705.jpg", ThumbnailSrc: "/media/garden-photos-hd/Vegetables/IMG_8705_thumbnail.jpg", Alt: "Vegetables Photo"},
            {Src: "/media/garden-photos-hd/Vegetables/IMG_8706.jpg", ThumbnailSrc: "/media/garden-photos-hd/Vegetables/IMG_8706_thumbnail.jpg", Alt: "Vegetables Photo"},
            {Src: "/media/garden-photos-hd/Vegetables/IMG_8736.jpg", ThumbnailSrc: "/media/garden-photos-hd/Vegetables/IMG_8736_thumbnail.jpg", Alt: "Vegetables Photo"},
            {Src: "/media/garden-photos-hd/Vegetables/IMG_8759.jpg", ThumbnailSrc: "/media/garden-photos-hd/Vegetables/IMG_8759_thumbnail.jpg", Alt: "Vegetables Photo"}
        ]
    }),
];