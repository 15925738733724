/*=========================================================
        Imports
=========================================================*/

import React from 'react';

import './BurgerMenu.css';

/*=========================================================
        Models
=========================================================*/

interface Props {
    isOpen: boolean;
    className?: string;
    onClick?: () => void;
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class BurgerMenu extends React.Component<Props, State> {

    /*=========================================================
            Getters
    =========================================================*/

    get classes() {
        let ret = [
            'burgermenu-container',
        ];
        if(undefined !== this.props.className) ret.push(this.props.className);
        if(true === this.props.isOpen) ret.push('burgermenu-is-open');
        return ret.join(' ');
    }
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        console.log('IsOpen', this.props.isOpen);
        
        return (
            <React.Fragment>
                <div className={this.classes} onClick={this.props.onClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </React.Fragment>
        )
    }
}