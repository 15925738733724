/*=========================================================
        Imports
=========================================================*/

import React from 'react';

/*=========================================================
        Models
=========================================================*/

interface Props {
    
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class OpeningTimes extends React.Component<Props, State> {

    /*=========================================================
            Getters
    =========================================================*/
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Builder Methods
    =========================================================*/

    buildDay(pDay: string, pTimes: string): React.JSX.Element {
        return (
            <div className="d-flex flex-row justify-content-between">
                <span>
                    {pDay}
                </span>
                <span>
                    {pTimes}
                </span>
            </div>
        )
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <div className="w-xxl-50 w-md-75 w-100 flex-wrap bg-primary border border-secondary-subtle w-100 rounded p-2">
                <div className="d-flex flex-row justify-content-center">
                    <h6 className="px-2 border-bottom border-4 border-secondary fw-bold fst-italic text-primary-emphasis text-center">
                        April 19th - October 1st
                    </h6>
                </div>
                {this.buildDay('Monday', '10:00 - 18:00')}
                {this.buildDay('Tuesday', '10:00 - 18:00')}
                {this.buildDay('Wednesday', '10:00 - 18:00')}
                {this.buildDay('Thursday', '10:00 - 18:00')}
                {this.buildDay('Friday', '10:00 - 18:00')}
                {this.buildDay('Saturday', '10:00 - 18:00')}
                {this.buildDay('Sunday', '10:00 - 18:00')}
            </div>
        )
    }
}