/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import { Link } from 'react-router-dom';

import './PageHeader.css';
import BurgerMenu from './BurgerMenu';

/*=========================================================
        Constants
=========================================================*/

const PAGES = [
    {
        Label: 'Home',
        Link: '/'
    },
    {
        Label: 'About Us',
        Link: '/about-us'
    },
    {
        Label: 'Gallery',
        Link: '/gallery'
    },
    {
        Label: 'Contact Us',
        Link: '/contact-us'
    }
];

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {
    IsMobileMenuExpanded: boolean;
}

/*=========================================================
        Component
=========================================================*/

export default class PageHeader extends React.Component<Props, State> {

    /*=========================================================
            Getters
    =========================================================*/

    get MobileNavClasses() {
        let classes = [
            'd-flex',
            'd-md-none',
            'flex-column',
            'gap-2',
            'text-center',
            'border-bottom',
            'mobile-navbar',
            'bg-primary-subtle',
            'pb-2',
        ];
        if(this.state.IsMobileMenuExpanded) classes.push('is-open')
        return classes.join(' ');
    }
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
        this.state = {
            IsMobileMenuExpanded: false
        };
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <div className="w-100 position-sticky" style={{top:0, zIndex: 9999}}>
                <div className="w-100 bg-primary d-flex flex-md-column flex-row justify-content-between justify-content-md-start">
                    <div className="text-primary-emphasis global-pageheader ps-1">
                        Silver end <br className="d-md-none"/>Secret Garden
                    </div>
                    <div className="d-none w-100 d-md-flex flex-row gap-2 justify-content-end pageheader-navbar py-1">
                        {
                            PAGES.map(
                                pPage => (
                                    <div className="px-2">
                                        <Link to={pPage.Link} className={"text-primary-emphasis pageheader-nav" + (window.location.pathname == pPage.Link ? ' pageheader-navbar-current' : '')}>
                                            {pPage.Label}
                                        </Link>
                                    </div>
                                )
                            )
                        }
                    </div>
                    <div className="d-md-none flex-shrink-1 d-flex align-items-center justify-content-end pe-3">
                        <BurgerMenu isOpen={this.state.IsMobileMenuExpanded} onClick={() => {this.setState({IsMobileMenuExpanded: !this.state.IsMobileMenuExpanded})}}/>
                    </div>
                </div>
                <div className={this.MobileNavClasses}>
                    {
                        PAGES.map(
                            pPage => (
                                <div className="px-2">
                                    <Link to={pPage.Link} className="text-primary mobile-nav-item">
                                        <strong>{pPage.Label}</strong>
                                    </Link>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        )
    }
}