/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import PageBanner from '../components/PageBanner';
import PageWrapper from '../components/PageWrapper';
import OpeningTimes from '../components/OpeningTimes';
import SubHeader from '../components/SubHeader';

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {
    
}

/*=========================================================
        Component
=========================================================*/

export default class Home extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Builder Methods
    =========================================================*/

    buildHeader(pText: string): React.JSX.Element {
        return (
            <h1 className="w-100 border-bottom border-4 border-primary fw-bold fst-italic text-primary-emphasis">
                {pText}
            </h1>
        )
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <PageWrapper>
                    <React.Fragment>
                        <PageBanner imageSrc="/media/garden-photos-hd/Entrance/IMG_8676.jpg"/>
                        {/* <div className="w-100 global-pageheader text-primary-emphasis text-center px-2 w-100">Welcome</div> */}
                        <div className="w-md-50 py-5 px-1 w-100 mx-auto d-flex flex-wrap flex-row gap-3">
                            <SubHeader text="Welcome"/>
                            <p className="w-100">
                                We extend a warm welcome to Silver End's Secret Garden, where an abandoned plot of land now thrives 
                                due to the dedication and commitment from our team. Explore our collection of photos where our visual 
                                journey is depicted, if you share our passion in gardening and wish to visit or have plants you wish 
                                to donate then please find more information on our contact page.
                            </p>
                            <SubHeader text="Opening Times"/>
                            <div className="w-100 d-flex flex-column align-items-center">
                                <div className="w-100 text-center">
                                    <p>
                                        If you'd like to use the garden for any
                                        special occasion then please feel free to reach out via the Contact Us page.
                                    </p>
                                </div>
                                <OpeningTimes/>
                            </div>
                        </div>
                    </React.Fragment>
                </PageWrapper>
            </React.Fragment>
        )
    }

}