/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import { Link } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class AboutUs extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <PageWrapper className="d-flex justify-content-center" style={{maxWidth: '98%'}}>
                    <div className="text-center h-fit-content d-flex flex-column gap-2 pb-5 pt-2" style={{width: 1200}}>
                        <h1 className="fst-italic fw-bold text-primary-emphasis">About Us</h1>
                        <div className="global-text-body text-primary-emphasis">
                            Welcome to Silver End’s Secret Garden and discover a hidden gem meticulously curated by a team of three devoted 
                            gardening enthusiasts. Together, we embarked on an ambitious year-long endeavour, investing countless hours of 
                            unwavering commitment to transforming an overlooked plot of land into making this garden something beautiful 
                            and as a result, an unloved piece of land in the village is now something everyone can appreciate. In our garden 
                            we have all types of plants growing from different types of flowers, fruit, vegetables and we even have a pond 
                            full of fish that is relaxing to be beside!
                        </div>
                        <div className="global-text-body text-primary-emphasis pt-5">
                            We encourage that you head on over and explore our <Link to="/gallery">Gallery</Link> page to partake in an visual exploration of the land’s 
                            remarkable before and after journey, a testament to our dedication and vision.
                        </div>
                    </div>
                </PageWrapper>
            </React.Fragment>
        )
    }

}