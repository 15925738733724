export { registerListener, unregisterListener, fireEvent }

const events = new Map<string, Function[]>();

/**
 * Registers a method to listen for the specified event
 * @param pEventName Event to register
 * @param pCallback Event Listener function
 * @param pThisContext This context to bind to the given function
 * @returns {Function} bound function
 */
function registerListener(pEventName: string, pCallback: Function, pThisContext: any): Function {
    let callbacks = events.get(pEventName);
    if(undefined === callbacks) {
        callbacks = [];
        events.set(pEventName, callbacks);
    }
    let boundCallback = pCallback.bind(pThisContext);
    callbacks.push(boundCallback);
    console.log('Registering ', pEventName);
    
    return boundCallback;
}

/**
 * Unregisters an event listener from the specified event
 * @param pEventName Event to unregister from
 * @param pCallback Bound callback that was returned from RegisterListener
 * @returns {Function} function that was removed, undefined if not found
 */
function unregisterListener(pEventName: string, pCallback: Function): Function | undefined{
    let callbacks = events.get(pEventName);
	if(undefined !== callbacks) {
        let index = callbacks.indexOf(pCallback);
        if(-1 !== index) {
            return callbacks.splice(index, 1)[0];
        }
	}
    console.log('Unregistering ', pEventName);
    return undefined;
}

/**
 * Fires off an event and calls all of the callback methods, returning true if 
 * @param pEventName Event to Fire
 * @param pArgs Args to pass to each listener
 * @returns {boolean} True if event listeners were called, false otherwise
 */
function fireEvent(pEventName: string, ...pArgs: any[]): boolean {
    let callbacks = events.get(pEventName);
    if(undefined !== callbacks) {
        callbacks.forEach(pCallback => {
            try {
                pCallback(...pArgs);
            } catch(ex) {
                console.error(ex);
            }
        });
    }
    console.log('Diring ', pEventName, pArgs);
    return callbacks !== undefined && callbacks.length !== 0;
}