/*=========================================================
        Imports
=========================================================*/

import React from 'react';

/*=========================================================
        Models
=========================================================*/

interface Props {
    text: string;
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class SubHeader extends React.Component<Props, State> {

    /*=========================================================
            Getters
    =========================================================*/
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <h1 className="w-100 border-bottom border-4 border-primary fw-bold fst-italic text-primary-emphasis">
                {this.props.text}
            </h1>
        )
    }
}