/*=========================================================
        Imports
=========================================================*/

import React, { createRef } from 'react';
import { EVENT_VIEW_IMAGE } from '../constants/Events';
import ViewImageEvent from '../models/ViewImageEvent';
import { fireEvent } from '../workers/EventWorker';
import ImageGallery from 'react-image-gallery';
import SubHeader from './SubHeader';

/*=========================================================
        Models
=========================================================*/

interface Image {
    Src: string;
    ThumbnailSrc: string;
    Alt: string;
}

interface Props {
    title: string;
    images: Image[];
}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class GallerySection extends React.Component<Props, State> {

    /*=========================================================
            Vars
    =========================================================*/

    galleryComp: any;

    /*=========================================================
            Events
    =========================================================*/

    onImageClick = (pImage: Image) => { fireEvent(EVENT_VIEW_IMAGE, {ImageSrc: pImage.Src} as ViewImageEvent) }
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <div className="w-md-75 w-100 py-4">
                    <h2 className="w-md-75 mx-md-auto border-top border-4 border-primary fw-bolder text-center text-primary-emphasis">
                        <div className="pt-2"/>{this.props.title}
                    </h2>
                    <div className="w-100 d-flex justify-content-center">
                        <div className="w-xxl-50 px-2 pb-1 rounded pt-2 bg-primary w-md-75 w-100">
                            <ImageGallery 
                                items={
                                    this.props.images.map(
                                        pImageMeta => ({
                                            thumbnail: pImageMeta.ThumbnailSrc,
                                            original: pImageMeta.Src,
                                            originalAlt: pImageMeta.Alt,
                                            thumbnailAlt: pImageMeta.Alt,
                                            originalClass: 'w-100'
                                        })
                                    )
                                }
                                showIndex
                                lazyLoad
                                ref={(i) => this.galleryComp = i}
                                onClick={() => {this.galleryComp.toggleFullScreen();}}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}