/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import { Link } from 'react-router-dom';

import './PageFooter.css';

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class PageFooter extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <div className="w-100 bg-primary-subtle position-relative">
                    <div className="w-50 mx-auto d-flex flex-column gap-2 text-center text-primary pagefooter-text py-2">
                        <div className="position-absolute d-none d-lg-block" style={{left: 15, height: "90%"}}>
                            <img src="/media/logo-light.svg" className="w-100 h-100"/>
                        </div>
                        <div>
                            Designed and developed by Jake and Jordan.
                        </div>
                        <div>
                            Got any questions or facing issues? <br/>Visit the page below:<br/>
                            <Link to="/contact-us" className="text-primary">Contact Us</Link>
                        </div>
                        <div>
                        </div>
                        <div>
                            Developed with React.JS<br/>
                            Version: V0.1.0
                        </div>
                    </div>
                </div>
                <div className="w-100 bg-primary" style={{height: 20}}>

                </div>
            </React.Fragment>
        )
    }
}