/*=========================================================
        Imports
=========================================================*/

import React from 'react';
import PageWrapper from '../components/PageWrapper';
import ImageGallery, { Image } from '../components/ImageGallery';
import PageBanner from '../components/PageBanner';
import { GALLERY_SECTIONS } from '../constants/Images';
import GallerySection from '../components/GallerySection';

import './Gallery.css';

/*=========================================================
        Constants
=========================================================*/

const IMAGES: Image[] = [
    {
        AltText:'Image One',
        Path: '/media/overgrown-one.jpg'
    },
    {
        AltText:'Image Two',
        Path: '/media/grass-cut-one.jpg'
    },
    {
        AltText:'Image Three',
        Path: '/media/pond.jpg'
    },
    {
        AltText:'Image Four',
        Path: '/media/steve.jpg'
    }
]

/*=========================================================
        Models
=========================================================*/

interface Props {

}

interface State {

}

/*=========================================================
        Component
=========================================================*/

export default class Gallery extends React.Component<Props, State> {
    
    /*=========================================================
            Constructor
    =========================================================*/

    constructor(pProps: Props) {
        super(pProps);
    }

    /*=========================================================
            Render
    =========================================================*/

    render(): React.JSX.Element {
        return (
            <React.Fragment>
                <PageWrapper>
                    <React.Fragment>
                        <PageBanner imageSrc="/media/garden-photos-hd/Pond/IMG_8686.jpg"/>
                        <div className="w-fit-content pb-5 pt-2 mx-auto px-2" style={{maxWidth: '98%'}}>
                            <h1 className="fw-bold fst-italic text-primary-emphasis text-center px-2">Gallery</h1>
                            <div className="global-text-body w-100 w-md-50 text-center mx-auto">
                                Welcome to our gallery here you can explore images of the garden at your own pace and we hope you enjoy! 
                            </div>
                            {/* <div className="text-center mx-auto" style={{width: "fit-content"}}>
                                <ImageGallery images={IMAGES} height={400} width={400}/>
                            </div> */}
                            <div className="w-100 d-flex flex-column align-items-center">
                                {
                                    GALLERY_SECTIONS.map(
                                        (pGallerySection, pIndex) => (
                                            <GallerySection key={pIndex} title={pGallerySection.SectionTitle} images={pGallerySection.Images}/>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </React.Fragment>
                </PageWrapper>
            </React.Fragment>
        )
    }

}